import React from "react";
import {
  StyledWhatDoWeDo,
  StyledWhatDoWeDoColumn,
  StyledWhatDoWeDoColumns,
  StyledWhatDoWeDoContent,
  StyledWhatDoWeDoParagraph,
  StyledWhatDoWeDoTitle,
} from "./style";
import { StyledGridRow } from "../../commons/Grid";
import Intro from "../Intro/Intro";
import ImpactCards from "../ImpactCards/ImpactCards";

const WhatDoWeDo = ({
  intro, title, columns, cards, 
}) => {
  return (
    <StyledWhatDoWeDo>
      <StyledGridRow noMargin>
        <Intro {...intro} noPadding />
        <StyledWhatDoWeDoContent>
          <StyledWhatDoWeDoTitle dangerouslySetInnerHTML={{ __html: title }} />
          <StyledWhatDoWeDoColumns>
            {columns.map((column, index) => (
              <StyledWhatDoWeDoColumn key={index}>
                {column.paragraphs.map((paragraph, index) => (
                  <StyledWhatDoWeDoParagraph
                    key={index}
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  />
                ))}
              </StyledWhatDoWeDoColumn>
            ))}
          </StyledWhatDoWeDoColumns>
        </StyledWhatDoWeDoContent>
        <ImpactCards cards={cards}/>
      </StyledGridRow>
    </StyledWhatDoWeDo>
  );
};

export default WhatDoWeDo;
